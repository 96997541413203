import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    step: {atual: "lead"},
    stepper: 1
  },
  getters: {
    getStep(state) {
      return state.step
    },
    getStepper(state) {
      return state.stepper
    }
  },
  mutations: {
    UPDATE_STEP(state, payload) {
      state.step.atual = payload
    },
    UPDATE_STEPPER(state, payload) {
      state.stepper = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
