import Vue from 'vue'
import App from './App.vue'
import axios from "axios";
import VueTheMask from "vue-the-mask";
import VueSweetalert2 from 'vue-sweetalert2';
import moment from 'moment';
import mixin from './mixin'
import Loader from 'vue-ui-preloader';
import router from './router'
import store from './store'
import SecureLS from "secure-ls";

import 'sweetalert2/dist/sweetalert2.min.css';
import '../public/assets/css/credit-card-form.css';

axios.defaults.baseURL = process.env.VUE_APP_DEFAULT_URL + 'api/';

const secureLS = new SecureLS(
  { 
    encodingType: 'aes', 
    isCompression: false, 
    encryptionSecret: process.env.VUE_APP_ENCRYPTION_LS_KEY
  });

Vue.use(VueTheMask)
Vue.use(VueSweetalert2)
Vue.use(Loader);
Vue.use({
  install(Vue) {
      Vue.prototype.$http = axios;
      Vue.prototype.$secureLS = secureLS;
  }
});

Vue.mixin(mixin);

Vue.filter('toCurrency', function (value, showSymbol = true) {
  if (typeof value !== "number") {
      return value;
  }

  var options = {
    style: 'currency',
    currency: 'BRL'
  };

  var formatter = new Intl.NumberFormat('pt-BR', options);

  if (!showSymbol) {
    return formatter.format(value).replace("R$", "");
  }

  return formatter.format(value);
});

Vue.filter('dataCarencia', function (value) {
  if (value) {
      return moment().add(value, 'months').add(1, 'days').format('DD/MM/YYYY');
  }
});

new Vue({
  el: "#app",
  router,
  store,
  render: h => h(App)
});