<template>
    <div class="stepper-wrapper">
      <div
        v-for="(item, index) in stepperItems"
        :key="index"
        :class="['stepper-item', item.classNames,]"
      >
        <div @click="logge(item.step)" class="step-counter"><i :class="item.icon"></i></div>
      </div>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  export default {
    name: 'StepperComp',
    data() {
      return {
        itn: 'hi',
        step: this.$store.getters.getStepper,
        stepperItems: [
          {
            step: 1,
            name: 'Cadastro',
            classNames: '',
            icon: 'fas fa-file-alt',
          },
          {
            step: 2,
            name: 'Veículo',
            classNames: '',
            icon: 'fas fa-car',
          },
          {
            step: 3,
            name: 'Planos',
            classNames: '',
            icon: 'fas fa-layer-group',
          },
          {
            step: 4,
            name: 'Dados',
            classNames: '',
            icon: 'fas fa-database',
          },
          {
            step: 5,
            name: 'Pagamento',
            classNames: '',
            icon: 'fas fa-file-invoice-dollar',
          },
          {
            step: 6,
            name: 'Vistoria',
            classNames: '',
            icon: 'fas fa-search-location',
          },
          {
            step: 7,
            name: 'Contrato',
            classNames: '',
            icon: 'fas fa-file-signature',
          },
        ],
      };
    },
  
    methods: {
      logge(step) {
        console.log(step);
      },
      stepmanager(num) {
        if (num === this.step) {
          return 'active';
        } else if (num < this.step) {
          return 'completed';
        } else {
          return '';
        }
      },
    },
  
    mounted() {
      this.stepperItems.forEach((item, index) => {
        item.classNames = this.stepmanager(item.step);
      });
    },
  };
  </script>
  

<style>
.step-name {
  font-size: 11px;
  color: #777777;
}

.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 36px;
  border-radius: 50%;
  background: #f0f0f0;
  margin-bottom: 6px;
  border: 1px solid #bdbdbd;
}

.stepper-item.active .step-counter {
  background-color: var(--cor-primaria);
  font-weight: bold;
  border-bottom: 1px solid #ffffff59
}

.step-counter i {
  font-size: 16px;
  color: #787878;
}

.stepper-item.active .step-counter i,
.stepper-item.completed i {
  color: white;
}

.stepper-item.completed .step-counter {
  background-color: var(--cor-primaria);
  border-bottom: 1px solid #ffffff59;
  outline: 2px solid white;
  outline-offset: -4.5px;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

.prev.button {
    background-color: rgb(255 255 255);
}
</style>