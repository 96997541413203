import Vue from 'vue'
import VueRouter from 'vue-router'
import LeadView from '../views/LeadView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    props: true,
    component: LeadView
  },
  {
    path: '/veiculo',
    name: 'veiculo',
    props: true,
    component: () => import('../views/VeicleView.vue')
  },
  {
    path: '/planos',
    name: 'planos',
    props: true,
    component: () => import('../views/PlanView.vue')
  },
  {
    path: '/cotacao',
    name: 'cotacao',
    props: true,
    component: () => import('../views/CotacaoView.vue')
  },
  {
    path: '/cliente',
    name: 'cliente',
    props: true,
    component: () => import('../views/ClientView.vue')
  },
  {
    path: '/pagamento',
    name: 'pagamento',
    props: true,
    component: () => import('../views/PaymentView.vue')
  },
  {
    path: '/vistoria',
    name: 'vistoria',
    props: true,
    component: () => import('../views/VistoriaView.vue')
  },
  {
    path: '/contrato',
    name: 'contrato',
    props: true,
    component: () => import('../views/ContratoView.vue')
  }
]

export const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,
  routes
})


router.afterEach(to => {
  Vue.prototype.$secureLS.set('ls_route_url', { path: to.fullPath })
})

let isFirstTransition = true;

router.beforeEach((to, from, next) => {
  const lastRoute = Vue.prototype.$secureLS.get('ls_route_url')
  
  if(isFirstTransition){

    if (lastRoute.path !== to.fullPath) {
      Vue.prototype.$secureLS.remove('ls_route_url')
      Vue.prototype.$secureLS.remove('ls_clt')
      Vue.prototype.$secureLS.remove('ls_py')
      Vue.prototype.$secureLS.remove('ls_cot')
      isFirstTransition = false;
      
      next(lastRoute.path);
      return;
    }
    
    isFirstTransition = false;
  }
  
  isFirstTransition = false;
  
  next();
  return;

});


export default router
