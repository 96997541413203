export default {
    data() {
        return {
            loadingDiv: true,
            loading: true,
            logoTipo: process.env.VUE_APP_LOGO,
        }
    },
    methods: {
        alert(titulo, message, tipo) {
            return this.$swal.fire(
                titulo,
                message,
                tipo,
            );
        },
        // remove caracteres especiais de string
        limpaString(value) {
            if (value) {
                return value.toString().replace(/[^0-9]+/g, '');
            }
        },
        //Validando o retorno do envio
        validarRetorno(titulo, data) {
            var mensagens = [data.message];
            var errors = data.errors || null;

            if (errors != null) {
                for (var index in errors) {
                    mensagens.push(errors[index].join(', '));
                }
            }

            this.$swal.fire(titulo, mensagens.join('<br />'), 'error');
        },
        // converte o fuckin input text de data para formato db
        converteData(date) {
            if (date) {
                let part = date.toString().split('/')
                return `${part[2]}-${part[1]}-${part[0]}`
            }
        },
        validarCpf(cpf) {
            var soma = 0;
            var resto;
            var strCPF = cpf.replace(/[^0-9]+/g, '');
            if (strCPF === '00000000000') {
                this.$swal.fire('Erro ao validar CPF', 'O CPF informado e inválido.', 'error')
                return false;
            }
            for (var i = 1; i <= 9; i++) {
                soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
            }
            resto = soma * 10 % 11;
            if (resto === 10 || resto === 11) {
                resto = 0;
            }
            if (resto !== parseInt(strCPF.substring(9, 10))) {
                this.$swal.fire('Erro ao validar CPF', 'O CPF informado e inválido.', 'error')
                return false;
            }
            soma = 0;
            for (var _i = 1; _i <= 10; _i++) {
                soma = soma + parseInt(strCPF.substring(_i - 1, _i)) * (12 - _i);
            }
            resto = soma * 10 % 11;
            if (resto === 10 || resto === 11) {
                resto = 0;
            }
            if (resto !== parseInt(strCPF.substring(10, 11))) {
                this.$swal.fire('Erro ao validar CPF', 'O CPF informado e inválido.', 'error')
                return false;
            }
            return true;
        },
        iniLoading() {
            this.loading = true;
        },
        stopLoading() {
            setTimeout(() => {
                this.loading = false;
                this.loadingDiv = false;
            }, 1000)
        },
        backToTop() {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
        isObjectFilled(obj) {
            return Object.values(obj).every(value => value !== null && value !== undefined && value !== '');
        },
        async getDadosCotacao(cotacaoId = null) {
            try {
                const cotacao_id = this.$route.query.cotacao_id ?? this.$secureLS.get('ls_cot').id;
                const response = await this.$http.get('/v1/veiculo-cotacao/cotacao/' + cotacaoId ?? cotacao_id, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                    },
                });

                if (response.data) {
                    return response.data.data;
                }

            } catch (e) {
                this.$secureLS.remove('ls_route_url')
                this.$secureLS.remove('ls_clt')
                this.$secureLS.remove('ls_py')
                this.$secureLS.remove('ls_cot')
                this.$store.commit('UPDATE_STEP', 'lead')
                this.$router.push({
                    name: "home",
                    path: "/",
                })
                this.$swal.fire('Opss!', 'Não foi possível localizar sua cotação. Tente novamente.', 'error');
            }
        },
        async verificaCliente() {
            try {
                const cliente_id = this.$route.query.id ?? this.$secureLS.get('ls_clt').id;
                await this.$http.get("/v1/cliente/" + cliente_id);

            } catch (err) {

                this.$store.commit('UPDATE_STEP', 'lead')
                this.$router.push({
                    name: "home",
                    path: "/",
                })
            }
        },
        async verificaCotacao() {
            try {
                const cotacao_id = this.$route.query.cotacao_id ?? this.$secureLS.get('ls_cot').id;

                const response = await this.$http.get('/v1/veiculo-cotacao/cotacao/' + cotacao_id, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                    },
                });

                if (response.data) {
                    return response.data.data;
                } else {
                    this.$swal.fire('Opss!', 'Não foi possível buscar os dados da sua cotação. Atualize a página e tente novamente.', 'error');
                }

            } catch (e) {
                this.$swal.fire('Opss!', 'Não foi possível localizar sua cotação. Atualize a página e tente novamente.', 'error');
            }
        },
        async getBoletoIntegra(id) {
            console.log('here');
            try {
                const response = await this.$http.get('/v1/pagamento/integra/transacao/get-boleto/' + id, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                    },
                });
                return response;
            } catch (e) {
                return;
            }
        }
    },
};