<template>
    <div class="root bg-section">
        <!-- Header -->
        <partHeader />

        <div class="container-fluid px-3">
            <LoadingComp v-if="loading"/>

            <FormLead class="form2" v-show="step.atual == 'lead' | stopLoading()" @validarRetorno="validarRetorno" :loading="loading"
                :avancar="(cliente) => { iniLoading(); step.atual = 'veiculo'; this.cliente = cliente }" />

        </div>

        <partFooter />
    </div>
</template>
  
<script>
/* eslint-disable */
import partHeader from "../components/parts/HeaderComp.vue";
import partFooter from "../components/parts/FooterComp.vue";
import FormLead from "../components/cliente/ClienteBase.vue";
import LoadingComp from "../components/LoadingComp.vue";

export default {
    components: {
        partHeader,
        partFooter,
        FormLead,
        LoadingComp
    },

    data() {
        return {
            step: {
                //lead, veiculo, cotacao, planos, cliente, pagamento, confirmacao, vistoria, contrato
                atual: 'lead' // Qual a primeira sessão que será carregada
            },
            cliente: {},
            cotacao: {},
            pagamento: {},
        }
    },

    mounted(){
        window.addEventListener("load", () => this.stopLoading());
    },
}
</script>
  